<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmBooking')" data-vv-scope="frmBooking" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12">
          <h5 class="box-title mb-3">Lista de distribuição de e-mail</h5>

          <div class="row">
          <!-- Booking Followers -->
          <div class="col-12 col-lg-3">
            <div class="card card-fullheight">
              <div class="card-body p-3">
                <h5 class="box-title m-2 mb-3">Booking</h5>

                <div class="row">
                  <div v-if="booking.id_porto_origem"
                    class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small>Origem</small>
                    <div class="text-capitalize">
                      {{ booking.id_porto_origem.nome }}
                    </div>
                  </div>

                  <div v-if="booking.id_porto_destino"
                    class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small>Destino</small>
                    <div class="text-capitalize">
                      {{ booking.id_porto_destino.nome }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small>Situação</small>
                    <div class="text-capitalize" :style="{'color': color}">
                      {{ status }}
                    </div>
                  </div>

                  <div v-if="booking.equipamentos"
                    class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small>Equipamentos</small>
                    <div v-for="equip in booking.equipamentos" :key="equip.id">
                      {{ equip.quantidade }} x {{ equip.idequipamento_maritimo.descricao }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-9">
            <div class="card card-fullheight">
              <div class="card-body p-3">
                <h5 class="box-title m-2">Seguidores</h5>
                <advanced-multiple-select
                  id-field="id"
                  labelField="name"
                  :no-options="'Buscar Funcionário'"
                  :tableFields="employeesUserFields"
                  :enableStatus="1"
                  :value="subscribers"
                  :options="employeesUserList"
                  v-bind:fetchOptions="employeesUserFetchOptions"
                  @onRemoved="userRemoved"
                  @onInput="userSelected"
                  @rowClicked="userSelected">
                </advanced-multiple-select>
              </div>
            </div>
          </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
                Salvar
              </button>
              <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import UserService from '@/services/UserService'
import BookingService from '@/services/BookingService'

export default {
  name: 'BookingConfig',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_booking') + ' - %s'
    }
  },
  data () {
    return {
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/booking',
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      //  Selected Values (Form)
      subscribers: [],
      colors: [
        '#ff0000', /* solicitar:  vermelho */
        '#f1ba26', /* solicitado: amarelo */
        '#ff0000', /* rejeitado:  vermelho */
        '#5ec65f', /* disponível: verde */
        '#5ec65f', /* confirmado: verde */
        '#ababab', /* utilizando: cinza */
        '#ababab', /* utilizado:  cinza */
        '#ababab', /* cancelado:   cinza */
        '#ababab' /* cancelado:   cinza */
      ],
      statusOptions: [],
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: {
          id: 0,
          label: 'Solicitar'
        },
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0
      },
      // List of options to select on select input
      employeesUserList: [],
      employeesUserFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'name',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    Loading,
    AdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.statusFetchOptions()
    this.loadBookingInfo()
    this.employeesUserFetchOptions()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    // Load basilares data
    this.loadSelectedUsers()
    this.isLoading = false
  },
  methods: {
    /**
     * Triggered when the user search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeesUserFetchOptions (search) {
      this.isLoading = true
      let filters = {
        'searchTerm': search
      }

      UserService.getEmployeesUser(filters).then(res => {
        this.employeesUserList = res.data.data
        this.isLoading = false
      })
    },
    userRemoved (val) {
    },
    userSelected (val) {
    },
    loadBookingInfo () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getBooking(id).then(res => {
        this.booking = res.data.data
        this.isLoading = false
      })
    },
    loadSelectedUsers () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getBookingSubscribers(id).then(res => {
        let records = res.data.data
        this.subscribers = records.map((record) => {
          return {
            id: record.id_user.id,
            email: record.id_user.email,
            name: record.id_user.name.concat(' (', record.id_user.username, ')'),
            username: record.id_user.username,
            status: 1
          }
        })
        this.isLoading = false
      })
    },
    statusFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        BookingService.getStatusOptions(filters).then(res => {
          _this.statusOptions = res.data.data
          resolve(_this.statusOptions)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = {
            booking: this.booking.id,
            subscribers: this.subscribers
          }

          BookingService.putSubscribers(data).then(response => {
            this.$router.push({ name: 'BookingView', params: { id: this.booking.id } })
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    }
  },
  computed: {
    color () {
      return parseInt(this.booking.situacao) !== null ? this.colors[this.booking.situacao] : '#000000'
    },
    status () {
      return this.statusOptions.length ? this.statusOptions[this.booking.situacao] : '-'
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
